import { ETFLinkButton } from '@cfra-nextgen-frontend/shared';
import exportIcon from '@cfra-nextgen-frontend/shared/src/assets/icons/export.svg';
import { StyledAvatar } from '@cfra-nextgen-frontend/shared/src/components/Avatar/Avatar';
import { Tooltip } from '@cfra-nextgen-frontend/shared/src/components/dataDisplay';
import { tooltipTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { Box, SxProps, ThemeProvider } from '@mui/material';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { useRef } from 'react';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';

export function ExportButton({ onClick, sx, icon = <StyledAvatar src={exportIcon} /> }: { onClick: () => void; sx?: SxProps; icon?: React.ReactNode }) {
    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);
    return (
        <>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
            <ETFLinkButton
                onClick={() => {
                    analyticsDataPickerRef.current?.registerAction({
                        action: joinWithDelimiter({
                            values: ['click on export'],
                        }),
                    });

                    onClick();
                }}
                sx={sx}
                content={
                    <ThemeProvider theme={tooltipTheme}>
                        <Tooltip title={'Export Watchlist'}>
                            <Box display={'flex'}>
                                {icon}
                                <span
                                    style={{
                                        fontSize: '13.5px',
                                        lineHeight: 1.5,
                                        marginLeft: '10px',
                                        color: '#007AB9',
                                    }}>
                                    Export
                                </span>
                            </Box>
                        </Tooltip>
                    </ThemeProvider>
                }
            />
        </>
    );
}
