import { Layout } from '@cfra-nextgen-frontend/shared';
import { AnalyticsDataContextProvider } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import { AgGridExtendedRef, defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGridCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { AgGridThemes, keepNoLeftPaddingOnMove } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/utils';
import { noResultText } from '@cfra-nextgen-frontend/shared/src/components/Card/BannerCard';
import { CardHeaderVariant1 } from '@cfra-nextgen-frontend/shared/src/components/CardHeaders/CardHeaderVariant1';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerResearchCompanyData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { getRowID } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/ssr';
import { ResearchDescriptionText } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/styledComponents';
import { TypographyStyle3 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import {
    getUserColumnDefs,
    saveUserSortModel,
    UserPreferencesSavingModes,
} from '@cfra-nextgen-frontend/shared/src/components/UserPreferences/utils/agGrid';
import { useUserEntitlements } from '@cfra-nextgen-frontend/shared/src/hooks/useUserEntitlements';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import { AgGridPreferencesEachElement, PreferenceType } from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { Box, createTheme, Grid, Stack, SxProps, ThemeProvider } from '@mui/material';
import { SortChangedEvent } from 'ag-grid-community';
import { getCellRendererValueProcessor } from 'components/AgGrid/renderers';
import { BadgeBiggestConcerns, BadgeHazardList } from 'components/BadgeBiggestConcerns';
import BiggestConcernNotEntitledContent from 'components/RiskMonitor/NotEntitledMessage';
import { cloneDeep } from 'lodash';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { hasBiggestConcernsEntitlement } from 'utils/biggestConcerns';
import { defaultAutosizePadding, defaultTooltipShowDelay } from 'utils/lookAndFeel';
import { Locations, UserPreferences, UserPreferencesSelectors, useUserPreferencesProps } from 'utils/preferences';
import { ConcernStateOptions } from './ConcernStateToggle';
import { additionDateColumns, dateColumns, Filters, getRiskMonitorHomeReqBody, RequiredFiltersData } from './Filters';
import { InformationBanner } from './InformationBanner';
import { InformationIcon } from './InformationIcon';
import { HIDE_HAZARD_LIST } from '@cfra-nextgen-frontend/shared/src/config';

const size = 25;
const sendSingleRequestConfig = {
    path: 'company/screener',
    apiName: ApiNames.Research,
    requestType: RequestTypes.POST,
    queryKeyFirstElement: 'researchCompanyScreenerQueryRiskMonitor',
};

export const RiskMonitorHomeContainerStyles: SxProps = {
    backgroundColor: '#FFFF',
    borderRadius: '10px',
    padding: '8px 16px 0px 16px',
    height: '100%',
    width: '100%',
};

const gridTheme = [AgGridThemes.GridThemeV2, 'ag-panel-top-position-fixed'];

const preferencesConfiguration = {
    useUserPreferencesProps,
    selectorConfiguration: {
        selector: UserPreferencesSelectors[UserPreferences.AccountingLensRiskMonitor],
    },
};

export const gridLoadingContainerStyles: SxProps = { margin: '48px 0', width: '100%', textAlign: 'center' };

export function RiskMonitorHome() {
    const { sendSingleRequest, getDataSource } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    if (!getDataSource) {
        throw new Error('getDataSource is not defined');
    }

    const [requiredFiltersData, setRequiredFiltersData] = useState<RequiredFiltersData | undefined>();
    const [filtersState, setFiltersState] = useState<Parameters<typeof getRiskMonitorHomeReqBody>[0]>();

    const { getUserPreferences, setUserPreferences } = useUserPreferences(
        preferencesConfiguration?.useUserPreferencesProps,
    );

    const { userEntitlements } = useUserEntitlements();
    const hasEntitlements: boolean = hasBiggestConcernsEntitlement(userEntitlements);

    const userPreferences = useMemo(() => {
        return getUserPreferences?.<AgGridPreferencesEachElement>({
            preferenceType: PreferenceType.AgGridPreferences,
            selector: preferencesConfiguration.selectorConfiguration.selector,
        });
    }, [getUserPreferences]);

    const userSortModel: SearchByParams | undefined = useMemo(() => {
        if (!userPreferences?.columnsSort || userPreferences?.columnsSort?.length === 0) {
            return undefined;
        }

        const userSort = userPreferences?.columnsSort?.find((columnDef) => Boolean(columnDef.colDef?.sort));

        if (!userSort) {
            return {
                orderBy: undefined,
                sortDirection: undefined,
            };
        }

        return {
            orderBy: userSort.colDef?.field || userSort.colDef?.colId,
            sortDirection: userSort.colDef?.sort || undefined,
        };
    }, [userPreferences]);

    const searchByParams = useMemo(() => {
        return {
            from: 0,
            securityType: 'research',
            view: 'risk_monitor_full',
            includeData: true,
            includeMetadata: true,
            size,
            config: {
                enabled: requiredFiltersData !== undefined,
            },
            path: 'company/screener',
            ...{
                ...requiredFiltersData?.searchByParams,
                orderBy: requiredFiltersData?.searchByParams?.orderBy,
                sortDirection: requiredFiltersData?.searchByParams?.sortDirection,
                ...userSortModel,
            },
        };
    }, [requiredFiltersData, userSortModel]);

    const companiesQuery = sendSingleRequest(
        searchByParams,
        sendSingleRequestConfig,
    ) as UseQueryResult<ScreenerResearchCompanyData>;

    const totalResultsText = (function () {
        const totalResults = companiesQuery?.data?.results?.total;
        return !companiesQuery?.isLoading && !totalResults
            ? 'Total Companies: 0'
            : `Total Companies: ${companiesQuery?.isLoading ? 'Loading...' : totalResults}`;
    })();

    const gridRef = useRef<AgGridExtendedRef>(null);
    const { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
        if (!companiesQuery.data || !requiredFiltersData) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }

        const companiesQueryData = cloneDeep(companiesQuery.data);

        if (companiesQueryData._viewdata.fields) {
            companiesQueryData._viewdata.fields = companiesQueryData._viewdata.fields.map((field) => {
                const key = Object.keys(field)[0];
                const value = field[key];

                if (value.header_name === 'Concern Status' && value?.cell_renderer_params?.[0].component) {
                    value.cell_renderer_params[0].component =
                        filtersState?.selectedConcernStateOption === ConcernStateOptions.Active
                            ? 'concern_current'
                            : 'concern_historical';
                }

                if (dateColumns.includes(key)) {
                    const isOrderByEqualToKey = key === requiredFiltersData?.dateColumnId;

                    value.header_name = additionDateColumns.includes(requiredFiltersData?.dateColumnId || '')
                        ? 'Date Added'
                        : 'Date Removed';
                    value.hide = !isOrderByEqualToKey;
                    value.default_sort_order = isOrderByEqualToKey ? 'desc' : undefined;
                }

                return {
                    [key]: value,
                };
            });
        }

        let result = extractFromScreenerData({
            screenerData: companiesQueryData,
            cardName: 'Risk Monitor',
            outerGetCellRendererValueProcessor: getCellRendererValueProcessor,
            keepNoLeftPadding: true,
        });

        if (HIDE_HAZARD_LIST){
            result.columnDefs = result.columnDefs.filter(columnDef=>columnDef.headerName!=="Concern Status");
        }

        const userPreferences = getUserPreferences?.<AgGridPreferencesEachElement>({
            preferenceType: PreferenceType.AgGridPreferences,
            selector: preferencesConfiguration.selectorConfiguration.selector,
        });

        if (!userPreferences) {
            return result;
        }

        return {
            ...result,
            columnDefs: getUserColumnDefs({
                initialColumnDefs: result.columnDefs,
                userPreferences,
                identicalColumns: dateColumns,
            }),
        };
    }, [companiesQuery.data, getUserPreferences, filtersState, requiredFiltersData]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? defaultMinWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const ssrDataSource = useMemo(() => {
        return getDataSource({
            metadataFields: companiesQuery?.data?._metadata?.fields || [],
            etfData: companiesQuery?.data?.results?.company || [],
            requestParams: {
                ...searchByParams,
                path: 'company/screener',
            },
            _resultsKey: 'company',
            size,
            extractSortParams: false,
            useQueryClient: true,
            queryKeyFirstElement: sendSingleRequestConfig.queryKeyFirstElement,
            defaultFrom: searchByParams.from,
        });
    }, [
        companiesQuery?.data?._metadata?.fields,
        companiesQuery?.data?.results?.company,
        getDataSource,
        searchByParams,
    ]);

    const onSortChanged = (event: SortChangedEvent) => {
        if (!['columnMenu', 'uiColumnSorted'].includes(event.source)) {
            return;
        }

        saveUserSortModel({
            api: event.api,
            setUserPreferences,
            selector: preferencesConfiguration?.selectorConfiguration?.selector,
        });
    };

    const onSortChangedRef = useRef(onSortChanged);

    const TableView = useMemo(() => {
        return (
            <AgGridCard
                ref={gridRef}
                useSSRMode
                getRowID={getRowID}
                SSRrowsToFetch={size}
                embedFullWidthRows
                columnDefs={columnDefs}
                gridTheme={gridTheme}
                SSRDataSource={ssrDataSource}
                showDefaultExportButton={false}
                customFlexibleColumns={customFlexibleColumns}
                getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                labelProps={{ width: '100%' }}
                labelPanelContainerStyles={{ paddingTop: '36px' }}
                rowMultiSelectWithClick
                useDragScroll
                onColumnMovedGetter={keepNoLeftPaddingOnMove}
                onColumnVisibleGetter={keepNoLeftPaddingOnMove}
                autoSizePadding={defaultAutosizePadding}
                tooltipShowDelay={defaultTooltipShowDelay}
                autosizeColumnsConfig={{
                    skipHeader: false,
                    skipHasPinnedColumnsCheck: true,
                }}
                suppressHeaderMenuButton={false}
                preferencesConfiguration={preferencesConfiguration}
                enableSavingUserColumnsOrder
                enableSavingUserColumnsVisibility
                enableSavingUserColumnsWidths
                useDisableColumnFlexOnResize
                useColumnWidthsFromColumnDefs
                setUserPreferencesOnReset={setUserPreferences}
                userPreferencesSavingMode={UserPreferencesSavingModes.Set}
                debouncedAutoSizeAllColumnsDelay={600}
                fullHeightGrid
                enableSavingUserSortModel={false}
                onSortChangedRef={onSortChangedRef}
            />
        );
    }, [columnDefs, customFlexibleColumns, getResizableMinWidthForColumn, setUserPreferences, ssrDataSource]);

    const showNotEntitled = userEntitlements && !hasEntitlements;
    const showSkeleton = !companiesQuery?.isFetched;
    const showNoResults =
        hasEntitlements &&
        companiesQuery?.isFetched &&
        !companiesQuery?.isLoading &&
        companiesQuery?.data?.results?.company?.length === 0;

    const header = useMemo(() => {
        return (
            <CardHeaderVariant1
                title='Risk Monitor'
                containerStyles={{
                    paddingBottom: '14px',
                }}
                titlePostfixSlot={
                    <>
                        <BadgeBiggestConcerns tooltipTitle='' containerSx={{ paddingLeft: '10px' }} />
                        <BadgeHazardList tooltipTitle='' />
                        <InformationIcon />
                    </>
                }
                slot3={
                    showNotEntitled ? (
                        <></>
                    ) : (
                        <Filters
                            setRequiredFiltersData={setRequiredFiltersData}
                            setFiltersState={setFiltersState}
                            userSortModel={userSortModel}
                        />
                    )
                }
            />
        );
    }, [userSortModel, showNotEntitled]);

    return (
        <ThemeProvider theme={createTheme()}>
            <AnalyticsDataContextProvider
                cfraDataLocal={{
                    actionData: {
                        cardName: Locations.RiskMonitor,
                    },
                }}>
                <Stack sx={RiskMonitorHomeContainerStyles}>
                    <InformationBanner onClose={() => gridRef.current?.updateGridTopPosition?.()} />
                    <Grid container>{header}</Grid>
                    {showSkeleton ? (
                        <Box sx={gridLoadingContainerStyles}>
                            <Layout.Skeleton height='10px' />
                        </Box>
                    ) : (
                        <>
                            {!showNotEntitled ? (
                                <Box sx={{ height: '46px', paddingTop: '13px' }}>
                                    <TypographyStyle3>{totalResultsText}</TypographyStyle3>
                                </Box>
                            ) : (
                                <BiggestConcernNotEntitledContent />
                            )}

                            {showNoResults && (
                                <Box sx={gridLoadingContainerStyles}>
                                    <ResearchDescriptionText>{noResultText}</ResearchDescriptionText>
                                </Box>
                            )}

                            {!showNoResults && !showNotEntitled && TableView}
                        </>
                    )}
                </Stack>
            </AnalyticsDataContextProvider>
        </ThemeProvider>
    );
}
