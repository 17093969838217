import { Views } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFButtonsPannel/ViewsPanel';
import { ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { ResearchComponent } from 'components/ResearchComponent';
import { SIZE_WHEN_NO_SIDEBAR } from 'components/ResearchComponent/components/constants';
import { GetFiltersJsxConfig } from 'components/ResearchComponent/components/filters/shared';
import { gridItemStyle4columns } from 'components/ResearchComponent/components/theme';
import { reportTypeImageMap } from 'utils/mappings';
import { Locations, UserPreferences, UserPreferencesSelectors, useUserPreferencesProps } from 'utils/preferences';

const filtersConfig: GetFiltersJsxConfig = {
    pillsRowComponentProps: {
        label: 'Document Type',
        filterMetadataKey: 'insights.research_report.research_type_name_pill',
        imageMap: reportTypeImageMap,
        customSortOrder: [
            ResearchTypeId.FrsIndustryReport,
            ResearchTypeId.FrsIRAPS,
            ResearchTypeId.FrsEducationalReport,
        ],
    },
    virtualizeAutocompleteComponentsProps: [
        {
            defaultInputLabel: 'Sector',
            filterMetadataKey: 'insights.research_report.composite_gics_sector_code_autocomplete',
        },
        {
            defaultInputLabel: 'Sub-Industry',
            filterMetadataKey: 'insights.research_report.composite_gics_sub_industry_code',
        },
        {
            defaultInputLabel: 'Author',
            filterMetadataKey: 'insights.research_report_author.analyst_id',
        },
    ],
    dateRangePickerComponentProps: {
        label: 'Date From/To',
        filterMetadataKey: 'insights.research_report.publish_timestamp',
    },
};

export function ResearchIndustryHome() {
    return (
        <ResearchComponent
            key='ResearchIndustryHome'
            resultsCardTitleOnMobile='IRAPS/Industry'
            filtersFormProps={{
                filtersConfig,
            }}
            queriesKeyFirstElementPostfix='industry'
            screenerSearchByParams={{
                view: 'research_hub_derivatives_no_region',
                size: SIZE_WHEN_NO_SIDEBAR,
            }}
            defaultFilters={{
                'insights.research_report.research_type_id': {
                    values: [
                        ResearchTypeId.FrsIndustryReport,
                        ResearchTypeId.FrsEducationalReport,
                        ResearchTypeId.FrsIRAPS,
                    ],
                },
            }}
            gridViewItemContainerStyles={gridItemStyle4columns}
            preferencesConfiguration={{
                useUserPreferencesProps,
                selectorConfiguration: {
                    [`${Locations.SearchResults}_${Views.TableView}` as const]: {
                        selector: UserPreferencesSelectors[UserPreferences.ResearchIndustrySearchResultsTableView],
                    },
                    [Locations.SearchResults]: {
                        selector: UserPreferencesSelectors[UserPreferences.ResearchIndustrySearchResults],
                    },
                },
            }}
        />
    );
}
