import {
    AnalyticsDataContext,
    AnalyticsDataContextProvider,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGridCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { AgGridSelectedRowsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import '@cfra-nextgen-frontend/shared/src/components/AgGrid/scss/GridThemeV2.scss';
import { AgGridThemes, keepNoLeftPaddingOnMove } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/utils';
import { CardHeaderVariant1 } from '@cfra-nextgen-frontend/shared/src/components/CardHeaders/CardHeaderVariant1';
import { ETFCard } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ScreenerChipThemeOutline } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';
import { ChipItem } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/types';
import {
    useFiltersForm,
    UseFiltersFormExternalInputProps,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { ScreenerResearchCompanyData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { watchListColumnWidth } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/constants';
import { getRowID } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/ssr';
import { SearchInputVariant1Ref } from '@cfra-nextgen-frontend/shared/src/components/SearchInput/SearchInputVariant1';
import { ToggleVariant1, ToggleVariant1Ref } from '@cfra-nextgen-frontend/shared/src/components/Toggle/ToggleVariant1';
import { Title } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTitles';
import { getUserColumnDefs } from '@cfra-nextgen-frontend/shared/src/components/UserPreferences/utils/agGrid';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import {
    customBreakpointTheme,
    customBreakPointValues,
} from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { useUserEntitlements } from '@cfra-nextgen-frontend/shared/src/hooks/useUserEntitlements';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import { AgGridPreferencesEachElement, PreferenceType } from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { ApiNames, LookupItems, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { getFiltersReqBody } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Collapse, IconButton, ThemeProvider, useMediaQuery } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { getCellRendererValueProcessor } from 'components/AgGrid/renderers';
import { FiltersButtonWithModal } from 'components/ResearchComponent/components/filters/FiltersButtonWithModal';
import { PageTheme } from 'components/themes/theme';
import { useWatchlistActions } from 'components/watchlists/hooks/useWatchlistActions';
import { cloneDeep, debounce } from 'lodash';
import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { companyFiltersRequestParams } from 'utils/api';
import { defaultAutosizePadding, defaultTooltipShowDelay } from 'utils/lookAndFeel';
import { Locations, UserPreferences, UserPreferencesSelectors, useUserPreferencesProps } from 'utils/preferences';
import { ScoresInfoBanner } from '../ScoresInfoBanner';
import { ScoresExportButton } from './ScoresExportButton';
import { getFiltersJsx, SearchCompanyFilter } from './getFiltersJsx';
import { ToggleOptions } from './utils';

const styles = {
    xsGridStandardStyle: {
        width: '100%',
        marginBottom: '15px',
        paddingBottom: '10px',
    },
};

const size = 25;
const sendSingleRequestConfig = {
    path: 'company/screener',
    apiName: ApiNames.Research,
    requestType: RequestTypes.POST,
    queryKeyFirstElement: 'researchCompanyScreenerQuery',
};

type ScoresCardProps = {
    watchlist: Omit<ReturnType<typeof useWatchlistActions>, 'unselectWatchlist'>;
    externalFilters?: Record<string, any>;
    externalChipItems?: Record<string, ChipItem>;
    onExternalChipDeleteClick?: UseFiltersFormExternalInputProps['onExternalChipDeleteClick'];
};

const cashFlowFilterMetadataKey = 'frs_cash_flow_score_drivers.decile';
const earningsFilterMetadataKey = 'frs_earnings_score_drivers.decile';
const VALID_DECILE_SCORES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const scoresBaseFilter: Record<string, any> = {
    $and: [
        {
            $or: [
                { 'frs_cash_flow_score_drivers.decile': { values: VALID_DECILE_SCORES } },
                { 'frs_earnings_score_drivers.decile': { values: VALID_DECILE_SCORES } },
            ],
        },
    ],
};

export type ScoresCardRefType = {
    resetToggle: () => void;
} & SearchInputVariant1Ref;

const gridTheme = [AgGridThemes.GridThemeV2, 'ag-panel-top-position-fixed'];

const preferencesConfiguration = {
    useUserPreferencesProps,
    selectorConfiguration: {
        selector: UserPreferencesSelectors[UserPreferences.ScoresResultsCard],
    },
};

const mobileInfoBannerTitle = 'Understanding CFRA Financial Metrics';

export const ScoresCard = forwardRef<ScoresCardRefType, ScoresCardProps>(
    (
        {
            watchlist: {
                createWatchlistButtonJsx,
                watchlistSelectJsx,
                operationsModalsJsx,
                selectedWatchlist,
                watchlistOperationsMenuIconJsx,
            },
            externalFilters,
            externalChipItems,
            onExternalChipDeleteClick,
        },
        ref,
    ) => {
        const { sendSingleRequest, getDataSource, getSsrDataExportFn, exportSSRAgGrid } = useContext(
            ProjectSpecificResourcesContext,
        );
        const { userEntitlements } = useUserEntitlements();
        const [selectedToggleOption, setSelectedToggleOption] = useState<ToggleOptions>();
        const [searchTerm, setSearchTerm] = useState<string>();

        const isMobileVariant = useMediaQuery(customBreakpointTheme.breakpoints.down('sm'));
        const [expandedTopic, setExpandedTopic] = useState<boolean>();

        if (!sendSingleRequest) {
            throw new Error('sendSingleRequest is not defined');
        }

        if (!getDataSource) {
            throw new Error('getDataSource is not defined');
        }

        if (!getSsrDataExportFn) {
            throw new Error('getSsrDataExportFn is not defined');
        }

        if (!exportSSRAgGrid) {
            throw new Error('exportSSRAgGrid is not defined');
        }

        const clearSearchRef = useRef<SearchInputVariant1Ref>(null);
        const ToggleVariant1Ref = useRef<ToggleVariant1Ref>(null);

        useImperativeHandle(ref, () => ({
            clearSearch: () => clearSearchRef.current?.clearSearch(),
            resetToggle: () => ToggleVariant1Ref.current?.resetToggle(),
        }));

        const searchInputOnChange = useMemo(() => {
            return debounce((e) => setSearchTerm(e.target.value), 200);
        }, []);

        useEffect(() => {
            return () => {
                searchInputOnChange.cancel();
            };
        }, [searchInputOnChange]);

        const entitlmentMappings = useMemo(() => {
            let userEarningsScoresEntitlements: string[] = [],
                userCashFlowEntitlements: string[] = [];

            userEntitlements?.packages.forEach((packg) => {
                userEarningsScoresEntitlements = userEarningsScoresEntitlements.concat(
                    packg.entitlements
                        ?.filter((ent) => ent.item_lid === LookupItems.ScoreTypeEarningsScore)
                        .map((ent) => ent.value) || [],
                );

                userCashFlowEntitlements = userCashFlowEntitlements.concat(
                    packg.entitlements
                        ?.filter((ent) => ent.item_lid === LookupItems.ScoreTypeCashFlow)
                        .map((ent) => ent.value) || [],
                );
            });

            return {
                [ToggleOptions.All]: userEarningsScoresEntitlements.length > 0 && userCashFlowEntitlements.length > 0,
                [ToggleOptions.EarningsScore]: userEarningsScoresEntitlements.length > 0,
                [ToggleOptions.CashFlow]: userCashFlowEntitlements.length > 0,
            };
        }, [userEntitlements]);

        const getToggleIndexFromEntitlementMappings = (
            entitlmentMappings: Record<ToggleOptions, boolean>,
        ): ToggleOptions => {
            return entitlmentMappings[ToggleOptions.All]
                ? ToggleOptions.All
                : entitlmentMappings[ToggleOptions.EarningsScore]
                ? ToggleOptions.EarningsScore
                : entitlmentMappings[ToggleOptions.CashFlow]
                ? ToggleOptions.CashFlow
                : ToggleOptions.All;
        };

        const togglePropsObj = useMemo(() => {
            return {
                options: Object.values(ToggleOptions).map((o) => ({ value: o, disabled: !entitlmentMappings[o] })),
                defaultSelectionIndex:
                    selectedToggleOption !== undefined
                        ? Object.values(ToggleOptions).findIndex((o) => o === selectedToggleOption)
                        : Object.values(ToggleOptions).indexOf(
                              getToggleIndexFromEntitlementMappings(entitlmentMappings),
                          ),
                onChange: (index: number) => setSelectedToggleOption(Object.values(ToggleOptions)[index]),
                ref: ToggleVariant1Ref,
            };
        }, [selectedToggleOption, entitlmentMappings]);

        const searchInputProps = useMemo(
            () => ({
                onChange: searchInputOnChange,
                clearButtonCallback: () => setSearchTerm(undefined),
                placeholder: 'Company Search',
                ref: clearSearchRef,
            }),
            [searchInputOnChange],
        );

        const memoisedGetFiltersJsx = useMemo(() => {
            return getFiltersJsx({
                toggleProps: {
                    ...togglePropsObj,
                    onChange: (index: number) => {
                        if (isMobileVariant) togglePropsObj.ref.current?.externalSetSelectedOptionIndex(index);
                        togglePropsObj.onChange(index);
                    },
                },
                searchInputProps,
                isMobileVariant,
            });
        }, [isMobileVariant, searchInputProps, togglePropsObj]);

        const { filtersFormJsx, filtersChipPanelJsx, filtersData, submitHandler, control } = useFiltersForm({
            filtersRequestParams: [
                {
                    ...companyFiltersRequestParams[0],
                    requestBody: {
                        requested_filters: [
                            cashFlowFilterMetadataKey,
                            earningsFilterMetadataKey,
                            'company_security.company_sector._lookup_gics_ind_grp_lid.key',
                            'company_security.company.region_country_lid',
                        ],
                    },
                },
                companyFiltersRequestParams[1],
            ],
            getFiltersJsx: memoisedGetFiltersJsx,
            externalChipItems,
            onExternalChipDeleteClick,
            chipStyles: {
                containerSx: { padding: isMobileVariant ? '18px 14px 15px 14px' : '0', maxHeight: 'unset' },
                onChipItemsExistSxProps: {
                    borderTop: '1px dotted #CCCCCC',
                    paddingTop: '24px',
                    paddingBottom: '15px',
                    overflowY: 'scroll',
                },
            },
            formStyle: {
                marginBottom: '24px',
            },
            useSubmitInFilters: true,
            chipTheme: isMobileVariant ? ScreenerChipThemeOutline : undefined,
        });

        const metadataKeyToAllValues = useMemo(() => {
            return Object.keys(filtersData?.data || {}).reduce((acc, metadataKey) => {
                acc[metadataKey] = filtersData?.data?.[metadataKey]?.items?.map((item) => item.key) || [];
                return acc;
            }, {} as Record<string, Array<string> | Array<number>>);
        }, [filtersData]);

        const gridRef = useRef<AgGridReact>(null);

        const { filtersPostData } = useContext(FiltersModalContext);

        const requestBody = useMemo(() => {
            let updatedFiltersPostData = cloneDeep(filtersPostData) || {};

            let _selectedToggleOption = selectedToggleOption;

            if (!_selectedToggleOption && filtersData)
                _selectedToggleOption = getToggleIndexFromEntitlementMappings(entitlmentMappings);

            switch (_selectedToggleOption) {
                case ToggleOptions.All:
                    // if all selected - filter by both columns with the same values
                    if (Object.keys(updatedFiltersPostData).includes(cashFlowFilterMetadataKey)) {
                        updatedFiltersPostData[earningsFilterMetadataKey] =
                            updatedFiltersPostData[cashFlowFilterMetadataKey];
                    }
                    break;
                case ToggleOptions.EarningsScore:
                    // if earnings selected - filter values in easrnings column to show only numbers (no '-' values)
                    if (!Object.keys(updatedFiltersPostData).includes(cashFlowFilterMetadataKey)) {
                        updatedFiltersPostData[cashFlowFilterMetadataKey] = {
                            values: metadataKeyToAllValues[earningsFilterMetadataKey],
                        };
                    }
                    // move cash flow filter to earnings filter
                    updatedFiltersPostData[earningsFilterMetadataKey] =
                        updatedFiltersPostData[cashFlowFilterMetadataKey];
                    // make sure that cash flow filter is not applied
                    delete updatedFiltersPostData[cashFlowFilterMetadataKey];
                    break;
                case ToggleOptions.CashFlow:
                    // is cash flow selected - filter values in cash flow column to show only numbers (no '-' values)
                    if (!Object.keys(updatedFiltersPostData).includes(cashFlowFilterMetadataKey)) {
                        updatedFiltersPostData[cashFlowFilterMetadataKey] = {
                            values: metadataKeyToAllValues[cashFlowFilterMetadataKey],
                        };
                    }
                    // make sure that earnings filter is not applied
                    delete updatedFiltersPostData[earningsFilterMetadataKey];
                    break;
            }

            const appliedFilters = { ...updatedFiltersPostData, ...externalFilters };
            const filters = cloneDeep(scoresBaseFilter);
            if (Object.keys(appliedFilters).length > 0) {
                filters.$and.push(appliedFilters);
            }
            return getFiltersReqBody(filters);
        }, [
            filtersPostData,
            selectedToggleOption,
            entitlmentMappings,
            externalFilters,
            metadataKeyToAllValues,
            filtersData,
        ]);

        const screenerCommonSearchByParams = useMemo(() => {
            return {
                securityType: 'research',
                view: 'scores',
                requestBody: requestBody,
                search: searchTerm,
            };
        }, [requestBody, searchTerm]);

        const searchByParams = useMemo(() => {
            return {
                ...screenerCommonSearchByParams,
                size,
                config: {
                    enabled: true,
                },
            };
        }, [screenerCommonSearchByParams]);

        const companiesQuery = sendSingleRequest(
            searchByParams,
            sendSingleRequestConfig,
        ) as UseQueryResult<ScreenerResearchCompanyData>;

        const { getUserPreferences, setUserPreferences } = useUserPreferences(
            preferencesConfiguration?.useUserPreferencesProps,
        );

        const { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
            if (!companiesQuery.data) {
                return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
            }

            let result = extractFromScreenerData({
                screenerData: companiesQuery.data,
                cardName: selectedWatchlist?.name || 'inst_watchlist',
                outerGetCellRendererValueProcessor: getCellRendererValueProcessor,
                keepNoLeftPadding: true,
                breakpointsConfig: [
                    {
                        from: 0,
                        to: customBreakPointValues.xl,
                        isActive: isMobileVariant,
                    },
                ],
            });

            let _selectedToggleOption = selectedToggleOption;

            if (!_selectedToggleOption)
                _selectedToggleOption = getToggleIndexFromEntitlementMappings(entitlmentMappings);

            switch (_selectedToggleOption) {
                case ToggleOptions.All:
                    break;
                case ToggleOptions.CashFlow:
                    result.columnDefs = result.columnDefs.filter((columnDef) => {
                        if (columnDef.field === earningsFilterMetadataKey) {
                            return false;
                        }
                        return columnDef;
                    });
                    break;
                case ToggleOptions.EarningsScore:
                    result.columnDefs = result.columnDefs.filter((columnDef) => {
                        if (columnDef.field === cashFlowFilterMetadataKey) {
                            return false;
                        }
                        return columnDef;
                    });
                    break;
            }

            const userPreferences = getUserPreferences?.<AgGridPreferencesEachElement>({
                preferenceType: PreferenceType.AgGridPreferences,
                selector: preferencesConfiguration.selectorConfiguration.selector,
            });

            if (!userPreferences) {
                return result;
            }

            return {
                ...result,
                columnDefs: getUserColumnDefs({
                    initialColumnDefs: result.columnDefs,
                    userPreferences,
                }),
            };
        }, [
            companiesQuery.data,
            selectedWatchlist?.name,
            isMobileVariant,
            selectedToggleOption,
            entitlmentMappings,
            getUserPreferences,
        ]);

        const getResizableMinWidthForColumn = useCallback(
            (headerName: string) =>
                headerName === 'undefined' ? watchListColumnWidth : minWidths[headerName] || defaultMinWidth,
            [minWidths],
        );

        const ssrDataSource = useMemo(() => {
            return getDataSource({
                metadataFields: companiesQuery?.data?._metadata?.fields || [],
                etfData: companiesQuery?.data?.results?.company || [],
                requestParams: {
                    path: 'company/screener',
                    ...screenerCommonSearchByParams,
                },
                _resultsKey: 'company',
                size,
            });
        }, [
            companiesQuery?.data?._metadata?.fields,
            companiesQuery?.data?.results?.company,
            getDataSource,
            screenerCommonSearchByParams,
        ]);

        const totalResultsText = (function () {
            const totalResults = companiesQuery?.data?.results?.total;
            return !companiesQuery?.isLoading && !totalResults
                ? 'Total Results: No records found'
                : `Total Results: ${companiesQuery?.isLoading ? 'Loading...' : totalResults}`;
        })();

        const analyticsDataContext = useContext(AnalyticsDataContext);
        const { setCfraDataLocalRef } = analyticsDataContext || {};

        useEffect(() => {
            setCfraDataLocalRef?.current?.((previousValue) => ({
                ...previousValue,
                actionData: {
                    ...previousValue?.actionData,
                    selectedToggleValue: selectedToggleOption || ToggleOptions.All,
                    search2Term: searchTerm,
                    totalResults: totalResultsText.replace('Total Results: ', ''),
                    isMobileVariant,
                },
            }));
        }, [setCfraDataLocalRef, totalResultsText, searchTerm, selectedToggleOption, isMobileVariant]);

        const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);

        return (
            <>
                <AnalyticsDataPicker ref={analyticsDataPickerRef} />
                <ETFCard containerStyles={{ paddingBottom: '0px', paddingTop: '22px' }}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            ...styles.xsGridStandardStyle,
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: isMobileVariant ? '1px dotted #CCCCCC' : 'none',
                        }}>
                        <Title
                            sx={{
                                lineHeight: 1,
                                paddingBottom: '0',
                            }}>
                            {isMobileVariant ? Locations.Scores : 'Filter'}
                        </Title>
                        <ScoresExportButton
                            gridRef={gridRef}
                            companiesQuery={companiesQuery}
                            screenerCommonSearchByParams={screenerCommonSearchByParams}
                        />
                        {isMobileVariant && (
                            <Grid marginLeft={'10px'}>
                                <AnalyticsDataContextProvider
                                    cfraDataLocal={{
                                        actionData: {
                                            cardName: Locations.Filters,
                                        },
                                    }}>
                                    <FiltersButtonWithModal
                                        filtersFormJsx={filtersFormJsx}
                                        filtersChipPanelJsx={filtersChipPanelJsx}
                                        externalChipItems={undefined}
                                        submitHandler={submitHandler}
                                        control={control}
                                        filterButtonTextStyle={{
                                            color: '#002B5A',
                                            fontSize: '13.5px',
                                        }}
                                    />
                                </AnalyticsDataContextProvider>
                            </Grid>
                        )}
                    </Grid>
                    {isMobileVariant && (
                        <Grid
                            sx={{
                                ...styles.xsGridStandardStyle,
                                borderBottom: isMobileVariant ? '1px dotted #CCCCCC' : 'none',
                            }}>
                            <Grid
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                                onClick={() => {
                                    analyticsDataPickerRef.current?.registerAction({
                                        action: joinWithDelimiter({
                                            values: [
                                                `${expandedTopic ? 'collapse' : 'expand'} accordion`,
                                                mobileInfoBannerTitle,
                                            ],
                                        }),
                                    });

                                    setExpandedTopic(!expandedTopic);
                                }}>
                                <Title sx={{ paddingBottom: '0', fontSize: '14px' }}>{mobileInfoBannerTitle}</Title>
                                <IconButton>
                                    {expandedTopic ? (
                                        <RemoveCircleOutlineIcon style={{ color: '#5a5a5a' }} />
                                    ) : (
                                        <AddCircleOutlineIcon style={{ color: '#5a5a5a' }} />
                                    )}
                                </IconButton>
                            </Grid>

                            <Collapse in={expandedTopic}>
                                <ScoresInfoBanner />
                            </Collapse>
                        </Grid>
                    )}
                    {isMobileVariant && (
                        <Grid
                            sx={{
                                ...styles.xsGridStandardStyle,
                                width: 'auto',
                            }}>
                            <ThemeProvider theme={PageTheme}>
                                <ToggleVariant1 {...togglePropsObj} />
                            </ThemeProvider>
                        </Grid>
                    )}
                    {isMobileVariant && (
                        <Grid
                            xs={12}
                            sx={{
                                ...styles.xsGridStandardStyle,
                            }}>
                            <SearchCompanyFilter searchInputProps={searchInputProps} />
                        </Grid>
                    )}
                    {!isMobileVariant && filtersFormJsx}
                    <Grid
                        item
                        xs={12}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        paddingBottom={'36px'}>
                        {watchlistSelectJsx}
                        <div style={{ marginLeft: 10 }}>{createWatchlistButtonJsx}</div>

                        {selectedWatchlist && <div>{watchlistOperationsMenuIconJsx}</div>}
                        {operationsModalsJsx}
                    </Grid>
                    {!isMobileVariant && filtersChipPanelJsx}
                    <Grid item xs={12}>
                        <CardHeaderVariant1
                            title={selectedWatchlist?.name}
                            subTitle={totalResultsText}
                            containerStyles={{
                                borderTop: '1px solid #AAAAAA',
                                paddingTop: '13px',
                                paddingBottom: '0px',
                            }}
                        />
                        <AgGridSelectedRowsContextProvider isSSRMEnabled={true}>
                            <AgGridCard
                                ref={gridRef}
                                useSSRMode
                                getRowID={getRowID}
                                SSRrowsToFetch={size}
                                embedFullWidthRows={true}
                                columnDefs={columnDefs}
                                gridTheme={gridTheme}
                                SSRDataSource={ssrDataSource}
                                showDefaultExportButton={false}
                                suppressRowClickSelection={true}
                                customFlexibleColumns={customFlexibleColumns}
                                getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                                labelProps={{ width: '100%' }}
                                labelPanelContainerStyles={{ paddingTop: '36px' }}
                                rowMultiSelectWithClick={true}
                                useDragScroll
                                onColumnMovedGetter={keepNoLeftPaddingOnMove}
                                onColumnVisibleGetter={keepNoLeftPaddingOnMove}
                                autoSizePadding={defaultAutosizePadding}
                                tooltipShowDelay={defaultTooltipShowDelay}
                                showSideHorizontalScrollIndicators
                                autosizeColumnsConfig={{
                                    skipHeader: false,
                                    skipHasPinnedColumnsCheck: true,
                                }}
                                suppressHeaderMenuButton={false}
                                preferencesConfiguration={preferencesConfiguration}
                                enableSavingUserSortModel
                                enableSavingUserColumnsOrder
                                enableSavingUserColumnsVisibility
                                enableSavingUserColumnsWidths
                                useDisableColumnFlexOnResize
                                useColumnWidthsFromColumnDefs
                                setUserPreferencesOnReset={setUserPreferences}
                            />
                        </AgGridSelectedRowsContextProvider>
                    </Grid>
                </ETFCard>
            </>
        );
    },
);
