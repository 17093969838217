import { AnalyticsDataContextProvider } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { InfiniteOptions } from '@cfra-nextgen-frontend/shared/src/components/InfiniteScroll/InfiniteOptions';
import {
    ItemVariant1,
    ItemVariant1Props,
} from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant1';
import { GetOptionsContainer } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/types';
import {
    AutoSuggestCompany,
    Hit,
    SearchResult,
} from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/types/opensearch';
import { GetOptionsComponentProps } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/TypeSearch';
import { getSuggesterCompanyProps } from 'analytics/utils';
import {
    getSuggesterInfiniteOptionsProps,
    getSuggesterInfiniteRequestParamsConfig,
} from 'features/home/components/InfiniteOptions/suggester';
import { useRef } from 'react';

type Props = {
    size: number;
    externalOnClickCallback?: (
        data: Hit<AutoSuggestCompany>,
        analyticsDataPickerRef: React.RefObject<AnalyticsDataPickerRefValue>,
    ) => void;
    passNavigateUrl?: boolean;
    getOptionsContainer: GetOptionsContainer;
    queryKeyFirstElementPostfix?: string;
    itemTextContainersSx?: ItemVariant1Props['textContainersSx'];
    setRowsCount?: (count?: number) => void;
};

export const determineGetCompanySuggesterOptions = ({
    size,
    externalOnClickCallback,
    passNavigateUrl = true,
    getOptionsContainer,
    queryKeyFirstElementPostfix,
    itemTextContainersSx,
    setRowsCount,
}: Props) => {
    const scrollThresholdPx = Math.round((62 * size) / 3); // take 33% of rendered size, assume each item height is 62px

    return function ({
        inputValue,
        resetOptionsRef,
        onLinkClickCallback,
        titleWithScrollableAreaProps,
        outerContainerRef,
    }: GetOptionsComponentProps) {
        const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);
        return (
            <InfiniteOptions<SearchResult<AutoSuggestCompany>, Hit<AutoSuggestCompany>>
                outerContainerRef={outerContainerRef}
                OptionsContainer={getOptionsContainer(titleWithScrollableAreaProps)}
                ref={resetOptionsRef}
                infiniteRequestParams={[
                    {
                        q: inputValue,
                        path: 'company/suggester',
                        config: {
                            enabled: Boolean(inputValue),
                        },
                        size,
                    },
                    {
                        queryKeyFirstElement: `companySuggesterQuery${queryKeyFirstElementPostfix}`,
                        ...getSuggesterInfiniteRequestParamsConfig<AutoSuggestCompany>(size),
                    },
                ]}
                outerSetOptionsCondition={Boolean(inputValue)}
                RowComponent={({ rowData }) => {
                    return (
                        <AnalyticsDataContextProvider
                            cfraDataLocal={{
                                actionData: getSuggesterCompanyProps({ company: rowData._source }),
                            }}>
                            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
                            <ItemVariant1
                                leftPartLeftText={rowData._source['company_security.security_trading.ticker_symbol']}
                                leftPartRightText={
                                    rowData._source['company_security.security_trading.exchange_lid.exchange_code']
                                }
                                navigateUrl={
                                    passNavigateUrl
                                        ? (
                                            rowData._source['company_security.security_trading.cfra_security_trading_id'] ? 
                                                `/company-profile/security/${rowData._source['company_security.security_trading.cfra_security_trading_id']}` : 
                                                `/company-profile/company/${rowData._source['company_security.company.cfra_company_id']}`
                                        )
                                        : undefined
                                }
                                onLinkClickCallback={() => {
                                    onLinkClickCallback?.();
                                    externalOnClickCallback?.(rowData, analyticsDataPickerRef);
                                }}
                                rightPartText={rowData._source['company_security.company.company_name']}
                                textContainersSx={itemTextContainersSx}
                            />
                        </AnalyticsDataContextProvider>
                    );
                }}
                rowsKeyPrefix={inputValue}
                noResultsFoundBoxSxProps={{ paddingLeft: '8px' }}
                loadingContainerStyles={{
                    paddingTop: '8px',
                    paddingBottom: '20px',
                }}
                {...getSuggesterInfiniteOptionsProps<AutoSuggestCompany>({ setRowsCount })}
                scrollThresholdPx={scrollThresholdPx}
            />
        );
    };
};
