import '@cfra-nextgen-frontend/shared/src/components/AgGrid/scss/GridThemeV2.scss';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerResearchCompanyData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { CircularProgress } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { ExportButton } from 'components/watchlists/ExportButton';
import { useCallback, useContext, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { CHUNK_SIZE, COMPANY_MAX_CONCURRENT_REQUESTS, COMPANY_MAX_RESULTS_WINDOW } from 'utils/api';

export function ScoresExportButton({
    gridRef,
    companiesQuery,
    screenerCommonSearchByParams,
}: {
    gridRef: React.RefObject<AgGridReact>;
    companiesQuery: UseQueryResult<ScreenerResearchCompanyData, unknown>;
    screenerCommonSearchByParams: SearchByParams;
}) {
    const [isLoading, setIsLoading] = useState(false);
    const { getSsrDataExportFn, exportSSRAgGrid } = useContext(ProjectSpecificResourcesContext);

    if (!getSsrDataExportFn) {
        throw new Error('getSsrDataExportFn is not defined');
    }

    if (!exportSSRAgGrid) {
        throw new Error('exportSSRAgGrid is not defined');
    }

    const SSRDataExportFn = useMemo(
        () =>
            getSsrDataExportFn?.({
                metadataFields: companiesQuery?.data?._metadata?.fields || [],
                requestParams: {
                    path: 'company/screener',
                    ...screenerCommonSearchByParams,
                },
                _resultsKey: 'company',
                batchConfig: {
                    total: companiesQuery?.data?.results?.total || 0,
                    chunkSize: CHUNK_SIZE,
                    maxConcurrentRequests: COMPANY_MAX_CONCURRENT_REQUESTS,
                    maxResultsWindow: COMPANY_MAX_RESULTS_WINDOW,
                },
            }),
        [
            companiesQuery?.data?._metadata?.fields,
            getSsrDataExportFn,
            screenerCommonSearchByParams,
            companiesQuery?.data?.results?.total,
        ],
    );

    const exportCallback = useCallback(() => {
        setIsLoading(true);
        exportSSRAgGrid(
            gridRef,
            SSRDataExportFn,
            {
                autoSizeColumns: true,
                fileName: 'CFRA Scores',
                metadata: {
                    cardName: 'CFRA Scores',
                },
                sheetName: 'Data Details',
                useMaxAsOfDate: undefined,
                getFormattedValuesFirst: true,
            },
            companiesQuery?.data?._metadata?.fields || [],
        )
            .then((result) => {
                if (result.isLoading !== undefined) {
                    setIsLoading(result.isLoading);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [companiesQuery?.data?._metadata?.fields, gridRef, exportSSRAgGrid, SSRDataExportFn]);

    return (
        <ExportButton
            onClick={() => exportCallback()}
            sx={{
                marginLeft: 'auto',
            }}
            icon={isLoading ? <CircularProgress size={15} sx={{ color: 'primary.main' }} /> : undefined}
        />
    );
}
